<template>
  <SliderCarrousel
    :slides="slides"
    :totalSteps="1"
    :callbackSegment="handlerEvents"
    :callback="navigateHome"
  >
    <template v-slot:content>
      <Button
        v-if="slides"
        class="margin-y"
        :text="warranty ?
          $translations.aftercall.slider[0].buttons[0].text :
          $translations.aftercall.slider[2].buttons[0].text "
        type='block'
        :disable="submitted"
        @clicked="nextStep"
      />
    </template>
  </SliderCarrousel>
</template>

<script>
import { PNG, Button, AfterCallWarranty } from '@seliaco/red-panda'
import { mapGetters } from 'vuex'
import { WCavailable, WCunavailable } from './configuration/configuration'
import SliderCarrousel from '@/components/misc/SliderCarrousel.vue'
import { WarrantyEvent } from '@/types/events'
export default {
  name: 'WCactivation',
  components: {
    SliderCarrousel,
    Button
  },
  props: {
    appointmentId: String
  },
  data () {
    return {
      loading: true,
      slides: null,
      warranty: this.$route.params.warranty,
      submitted: false
    }
  },
  beforeMount () {
    this.getSlider()
  },
  methods: {
    getSlider () {
      this.slides = this.warranty
        ? WCavailable(this.$translations, PNG)
        : WCunavailable(this.$translations, PNG)
      if (this.warranty) {
        WarrantyEvent.Warranty_Call_Activation_Viewed()
      } else {
        WarrantyEvent.Warranty_Call_Unavailable_Viewed()
      }
      this.loading = false
    },
    nextStep () {
      if (this.submitted) {
        return
      }

      if (this.warranty) {
        AfterCallWarranty.requestPsychologyWarranty(this.appointmentId)
          .then(() => {
            this.handlerEvents('Activar sesión gratis')
            this.$router.push({
              name: 'WarrantyCall'
            })
          })
          .catch(() => {
            this.$toast({
              text: this.$translations.error.default,
              severity: 'error'
            })
            this.$router.push({ name: 'Home' })
          })
      } else {
        this.handlerEvents('Agendar orientación')
        this.$router.push({ name: 'RecommendedCounselling' })
      }

      this.submitted = true
    },
    navigateHome () {
      this.handlerEvents('En otro momento')
      this.$router.push({
        name: 'Home'
      })
    },
    handlerEvents (val) {
      if (this.warranty) {
        WarrantyEvent.Warranty_Call_Activation_Clicked(val)
      } else {
        WarrantyEvent.Warranty_Call_Unavailable_Clicked(val)
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    })
  }
}
</script>
