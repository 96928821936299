export const WCavailable = ($translations, img) => ([
  {
    img: img.GiftSecondChance,
    title: $translations.aftercall.slider[0].title,
    text: $translations.aftercall.slider[0].text,
    background: 'gradient-alt',
    button: {
      text: $translations.aftercall.slider[0].buttons[1].text,
      type: 'outline'
    }
  }
])

export const WCactive = ($translations, img) => ([
  {
    img: img.CheckIllustration,
    title: $translations.aftercall.slider[1].title,
    text: $translations.aftercall.slider[1].text,
    background: 'gradient-alt',
    button: {
      text: $translations.aftercall.slider[1].buttons[1].text,
      type: 'outline'
    }
  }
])

export const WCunavailable = ($translations, img) => ([{
  img: img.Start,
  title: $translations.aftercall.slider[2].title,
  text: $translations.aftercall.slider[2].text,
  background: 'gradient-alt',
  button: {
    text: $translations.aftercall.slider[2].buttons[1].text,
    type: 'outline'
  }
}])
